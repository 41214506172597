<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <div class="transition-box">
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="params.status">
                            <el-option value="" label="全部"></el-option>
                            <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分组名称" prop="name">
                        <el-input v-model="params.name" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-plus" size="mini" v-permission="'waiter:crowd:add'" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="分组名称" prop="name"></el-table-column>
            <el-table-column align="center" label="服务号" prop="servicesList">
                <template v-slot="scope">
                    <span v-for="servicesId in scope.row.servicesList" :key="servicesId">
                        <span v-for="item in servicesList" :key="item.servicesId"
                              v-if="item.servicesId === servicesId">
                            <el-tag>{{ item.name }}</el-tag>
                        </span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态" prop="status">
                <template v-slot="scope">
                    <span v-for="item in statusList" v-if="scope.row.status === item.value">{{ item.label }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-edit-outline" v-permission="'waiter:crowd:edit'" @click="handleUpdate(scope.row)">
                        修改
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-pause" v-permission="'waiter:crowd:disable'" v-if="scope.row.status === 0"
                               @click="handleDisable(scope.row)">禁用
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-play" v-permission="'waiter:crowd:enable'" v-if="scope.row.status === 9"
                               @click="handleEnable(scope.row)">启用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page.sync="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-dialog :title="dataFromAdd?'添加数据':'修改数据'" :visible.sync="dataFromSync" width="60%">
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" style="width: 400px" label-width="100px"
                     size="medium">
                <el-form-item label="分组名称" prop="name">
                    <el-input v-model="dataFrom.name"></el-input>
                </el-form-item>
                <el-form-item label="服务号" prop="servicesList">
                    <el-row>
                        <el-col :span="18">
                            <el-select v-model="selectedServiceId" style="width: 100%">
                                <el-option v-for="item in servicesList" :key="item.servicesId" :label="item.name"
                                           :value="item.servicesId"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6" style="text-align: center">
                            <el-button type="primary" icon="el-icon-plus" size="medium"
                                       @click="handleAddTag"></el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-tag v-for="tag in dataFrom.servicesList" :key="tag" closable @close="handleCloseTag(tag)">
                            <span v-for="item in servicesList" :key="item.servicesId"
                                  v-if="tag === item.servicesId">{{ item.name }} - {{ item.servicesNo }}</span>
                        </el-tag>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as servicesCrowdApi from "@/api/waiter/waiterServicesCrowd"
import * as servicesApi from "@/api/waiter/waiterServices"

export default {
    name: "WaiterServicesCrowd",
    data() {
        return {
            selectedServiceId: null,
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                servicesList: []
            },
            dataFromRules: {
                // servicesList: [{required: true, message: '请添加服务号', trigger: 'blur'}],
                name: [{required: true, message: '请输入分组名称', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10,
                status: 0,
            },
            loading: false,
            dataList: [],
            total: 0,
            idList: [],
            servicesList: [],
        }
    },
    mounted() {
        this.findPage()
        this.findServicesList()
    },
    methods: {
        handleCloseTag(tag) {
            this.dataFrom.servicesList.splice(this.dataFrom.servicesList.indexOf(tag), 1);
        },
        handleAddTag() {
            if (this.selectedServiceId) {
                let arr = this.dataFrom.servicesList
                let arrSet = new Set(arr).add(this.selectedServiceId)
                this.dataFrom.servicesList = [...arrSet]
            } else {
                this.$message.error("请选择要添加的服务号")
            }
        },
        findServicesList() {
            servicesApi.findList().then(res => {
                if (res.success) {
                    this.servicesList = res.data
                }
            })
        },
        /**
         * 取消
         */
        cancel() {
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    servicesCrowdApi.saveData(this.dataFrom).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.findPage()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        /**
         * 启用
         */
        handleEnable(row) {
            let ids = (row.scId ? [row.scId] : null) || this.idList
            if (ids) {
                servicesCrowdApi.handleEnable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 禁用
         */
        handleDisable(row) {
            let ids = (row.scId ? [row.scId] : null) || this.idList
            if (ids) {
                servicesCrowdApi.handleDisable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 修改
         * @param row
         */
        handleUpdate(row) {
            this.dataFromSync = true
            this.dataFromAdd = false
            this.dataFrom = Object.assign({}, row)

        },
        /**
         * 添加
         */
        handleAdd() {
            this.dataFromSync = true
            this.dataFromAdd = true
            this.dataFrom = {
                servicesList: []
            }
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
            }
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            servicesCrowdApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    for (let item of this.dataList) {
                        if (item.servicesIds) {
                            item.servicesList = item.servicesIds.split(",").map(Number)
                        }
                    }
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>